import React, { useState } from 'react';
import './Footer.css';
import { handleGetInfo } from '../../Hooks/FirebaseFuncitons';
import logoTrans from '../../Assets/LOGO_CABECERA_TRANS.png';
import { sendInfoEmblu } from '../../Hooks/EmblueHooks';
import { Loader } from '../Loader/Loader';

export const Footer = () => {
  const [formData, setFormData] = useState({
    Nombre: '',
    Email: ''
  });
  const [loading, setLoading] = useState(false); // Estado para el componente Loading

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value  // Aquí es donde se actualiza el valor correspondiente en el estado
    }));
  };

  const handleSubmitDm = async (e) => {
    e.preventDefault(); // Prevenir comportamiento por defecto del formulario

    try {
      setLoading(true); // Mostrar el componente Loading antes de enviar
      await sendInfoEmblu(formData);
      await handleGetInfo(formData);
      // Vaciar los inputs después del envío
      setFormData({
        Nombre: '',
        Email: ''
      });
      alert('Información enviada con éxito');
    } catch (error) {
      console.error('Error al enviar:', error);
      alert('Hubo un error al enviar la información. Inténtelo nuevamente.');
    } finally {
      setLoading(false); // Ocultar el componente Loading después de finalizar
    }
  };

  return (
    <div style={{backgroundColor: 'rgb(47, 46, 46)', margin: ''}}>
      <div className="footer">
        <div className="footer-content-img">
          <img src={logoTrans} alt="" />
        </div>
        <div className="footer-content">
          <div className="newsletter">
            <div>
              <p>Quiero recibir novedades</p>
            </div>
            <form className="form-container" onSubmit={handleSubmitDm}>
              <input 
                type="text" 
                placeholder="(Nombre)"  // Placeholder personalizado
                name="Nombre"
                value={formData.Nombre}
                required  // El campo es requerido
                onChange={(e) => handleInputChange(e)}
              />
              <input 
                type="email"
                placeholder="(Correo electrónico)"  // Placeholder personalizado
                name="Email"
                value={formData.Email}
                required  // El campo es requerido
                onChange={(e) => handleInputChange(e)}
              />
              <button className='btn-send-footer' type="submit">Enviar</button>
            </form>
          </div>
        </div>
      </div>
      {loading && <Loader />}
      <p className="footer-bottom"> powered by Goldmund S.A </p>
    </div>
  );
};
