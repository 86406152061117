import React, { useState, useEffect } from 'react';
import { parsePhoneNumber, format, isValidPhoneNumber } from 'libphonenumber-js';

const countryCodes = {
  AR: '+549',
  CH: '+56',
  UR: '+598',
  BR: '+55',
  PA: '+595'
};

const PhoneForm = ({ phoneNumber, setPhoneNumber }) => {
  const [selectedCountry, setSelectedCountry] = useState('+549');
  const [isValid, setIsValid] = useState(true);

  useEffect(() => {
    if (phoneNumber) {
      try {
        const numberWithCode = phoneNumber.startsWith('+') ? phoneNumber : `${selectedCountry}${phoneNumber}`;
        //console.log(numberWithCode)
        const number = parsePhoneNumber(numberWithCode).number;
        //console.log(number)
        if (number) {
          const formattedNumber = format(number, 'International');
          //console.log(formattedNumber)
          setPhoneNumber(formattedNumber);
          setIsValid(isValidPhoneNumber(formattedNumber, selectedCountry));
        } else {
          setIsValid(false);
        }
      } catch (error) {
        console.error('Error formatting phone number:', error);
        setPhoneNumber(phoneNumber); // Fallback to raw phone number
        setIsValid(false);
      }
    }
  }, [selectedCountry, phoneNumber]);

  const handleCountryChange = (event) => {
    const newCountryCode = event.target.value;
    setSelectedCountry(newCountryCode);

    // Update phone number to reflect the new country code
    const numberWithoutCode = phoneNumber.startsWith(countryCodes[selectedCountry]) 
    ? phoneNumber.slice(countryCodes[selectedCountry].length)
    : phoneNumber;
    setPhoneNumber(``);
    //console.log(numberWithoutCode)
  };

  const handlePhoneNumberChange = (event) => {
    const number = event.target.value;
    setPhoneNumber(number);

    // Validate phone number with the selected country code
    const numberWithCode = number.startsWith('+') ? number : `${selectedCountry}${number}`;
    try {
      const parsedNumber = parsePhoneNumber(numberWithCode, selectedCountry);
      setIsValid(parsedNumber ? isValidPhoneNumber(parsedNumber.number, selectedCountry) : false);
    } catch (error) {
      setIsValid(false);
    }
  };

  return (
    <div style={{ display:"flex",alignItems:"center"}}>
      <div>
        {/* <label htmlFor="country">Selecciona tu país:</label> */}
        <select id="country" value={selectedCountry} onChange={handleCountryChange}>
          {Object.entries(countryCodes).map(([country, code]) => (
            <option key={code} value={code}>
              {country} ({code})
            </option>
          ))}
        </select>
      </div>
      <div style={{ display:"flex",alignItems:"center"}}>
        {/* <label htmlFor="phone">Número de teléfono:</label> */}
        <input
          id="phone"
          type="text"
          value={phoneNumber}
          onChange={handlePhoneNumberChange}
          placeholder="Teléfono"
          required
        />
        {!isValid && <p style={{ color: 'red' }}>Número no válido.</p>}
      </div>
    </div>
  );
};

export default PhoneForm;
