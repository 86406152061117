import React, { useState } from 'react';
import './Subscription.css'
import { handleSendForm } from '../../Hooks/FirebaseFuncitons';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'; 
import PhoneForm from './SubComps/PhoneForm';
import EmailForm from './SubComps/EmailForm';
import { ModalCaptcha } from './ModalCaptcha/ModalCaptcha';
import LocationAutocomplete from './SubComps/LocationAutocomplete';
import FileUpload from './FileUpload/FileUpload';
import { Loader } from '../Loader/Loader';

export const Subscription = () => {
    const [ load, setLoad] = useState(false)
    const [formType, setFormType] = useState('individual');
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [formData, setFormData] = useState({
        individual: {
            nombre: '',
            apellido: '',
            pseudonimo: '',
            email: '',
            dni: '',
            fechaNacimiento: '',
            domicilio: '',
            ciudad: '',
            provincia: '',
            telefono: '',
            archivoProyecto: null,  // Campo para archivo de proyecto
            archivoParticipante: null,  // Campo para archivo del participante
            archivoFirma:null,
        },
        grupal: {
            nombre: '',
            apellido: '',
            pseudonimo: '',
            email: '',
            dni: '',
            fechaNacimiento: '',
            domicilio: '',
            ciudad: '',
            provincia: '',
            telefono: '',
            archivoProyecto: null,  // Campo para archivo de proyecto
            archivoParticipante: null,  // Campo para archivo del participante
            archivoFirma:null,
            participantes: [],
            
        }
    });
  
    
//Cambiar tipo de formulario, borra lo que completaste antes 
    const changeForm = (e) =>{ 
            if (e ==='individual'){
                setFormType('individual')
                setFormData(initialFormData);
            }else if(e ==='grupal'){
                setFormType('grupal')
                setFormData(initialFormData);
            }
    }
    
//Agregado de archivos 
 
    
//agregado de valores al objeto  segun el group y/o el index

const handleInputChange = (e, group = 'individual', index = null) => {
    const { name, value, files } = e.target;  // Añade 'files'
    const inputValue = files ? files[0] : value;  // Si es un archivo, usa el primer archivo
    if (group === 'grupal' && index !== null) {
        const fieldName = name.split('_')[0];  // Asegúrate de dividir correctamente el nombre

        setFormData((prevData) => {
            const updatedParticipants = [...prevData.grupal.participantes];
            updatedParticipants[index] = {
                ...updatedParticipants[index],
                [fieldName]: inputValue  // Asigna correctamente el archivo o valor
            };
            return {
                ...prevData,
                grupal: {
                    ...prevData.grupal,
                    participantes: updatedParticipants
                }
            };
        });
    } else {
        setFormData((prevData) => ({
            ...prevData,
            [group]: {
                ...prevData[group],
                [name]: inputValue  // Maneja tanto texto como archivos
            }
        }));
    }
};


// agregar participantes
    const handleAddParticipant = () => {
        if (formData.grupal.participantes.length < 4) { // Adjusted limit to 5 participants
            setFormData((prevData) => ({
                ...prevData,
                grupal: {
                    ...prevData.grupal,
                    participantes: [
                        ...prevData.grupal.participantes,
                        {
                            nombre: '',
                            apellido: '',
                            email: '',
                            dni: '',
                            fechaNacimiento: '',
                            domicilio: '',
                            ciudad: '',
                            provincia: '',
                            telefono: '',   
                            archivoProyecto: null,  
                            archivoParticipante: null, 
                            archivoFirma:null,
                        }
                    ]
                }
            }));
        } else {
            alert('Máximo 5 personas por equipo');
        }
    };
// remover participantes
    const handleRemoveParticipant = (index) => {
        const updatedParticipants = formData.grupal.participantes.filter((_, i) => i !== index);
        setFormData({
            ...formData,
            grupal: {
                ...formData.grupal,
                participantes: updatedParticipants
            }
        });
    };
// valores iniciales 
    const initialFormData = {
        individual: {
            nombre: '',
            apellido: '',
            pseudonimo: '',
            email: '',
            dni: '',
            fechaNacimiento: '',
            domicilio: '',
            ciudad: '',
            provincia: '',
            telefono: '',
            proyecto:'',
            participante:'',
            archivoProyecto: null,  
            archivoParticipante: null,  
            archivoFirma:null,
        },
        grupal: {
            nombre: '',
            apellido: '',
            pseudonimo: '',
            email: '',
            dni: '',
            fechaNacimiento: '',
            domicilio: '',
            ciudad: '',
            provincia: '',
            telefono: '',
            proyecto:'',
            participante:'',
            participantes: [],
            archivoProyecto: null,  
            archivoParticipante: null, 
            archivoFirma:null,
        }
    };
//  Auto Completado  de ubicaciones segun el usuario 
const handleLocationChange = (addressData, group = 'individual', index = null) => {
        const { domicilio, ciudad, provincia, pais } = addressData;
        // pais tiene la info de la provincia, no tuve ganas de cambiarlo antes 
        if(group === 'grupal' && index == null){
           // console.log('grup')
            handleInputChange({ target: { name: `domicilio`, value: domicilio } }, group);
            handleInputChange({ target: { name: `ciudad`, value: ciudad } }, group);
            handleInputChange({ target: { name: `provincia`,value: pais } }, group);

        }else if ( group === 'grupal' && index !== null) {
            handleInputChange({ target: { name: `domicilio_${index}`, value: domicilio } }, group, index);
            handleInputChange({ target: { name: `ciudad_${index}`, value: ciudad } }, group, index);
            handleInputChange({ target: { name: `provincia_${index}`,value: pais } }, group, index);
        } else {
           
            handleInputChange({ target: { name: 'domicilio', value: domicilio } }, group);
            handleInputChange({ target: { name: 'ciudad', value: ciudad } }, group);
            handleInputChange({ target: { name: 'provincia', value: pais } }, group);
        }
    };

// En lugar de enviar el formulario directamente, abrir el modal
    const onSubmit = (e) => {
        e.preventDefault();
        setIsModalOpen(true);
    };

// confirmacion del modal 
    const handleModalConfirmation = async () => {
            //console.log('test')
            setLoad(true)
            try {
                //envio a firebase
                await handleSendForm(formData, formType);
                // restea todos los campos 
                        setLoad(false)
                        setFormData(initialFormData);
                        setFormType('individual');
                        toast.success('¡El envío se completó!', {
                                    position: "top-right",
                                    autoClose: 2500,
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                    progress: undefined,
                                    theme: "light",
                                    onClose: () => {
                                        window.location.reload(); // Refresca la página al cerrar el toast
                                    },
                        });
            } catch (error) {
                    console.error('Error al enviar el formulario:', error);
                    toast.error('Ocurrió un error, intenta más tarde!', {
                                    position: "top-right",
                                    autoClose: 3500,
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                    progress: undefined,
                                    theme: "light",
                            });
                        }
                };
            //console.log(formData)
    return (
        <div className="subscription-container">
            <div className="subscription-title">INSCRIPCIÓN</div>
            
            <div className="form-type-selector">
                <div className="selector">
                    <div
                        className={`selector-indicator ${formType === 'grupal' ? 'grupal' : ''}`}
                    ></div>
                    <div
                        className={`selector-button ${formType === 'individual' ? 'active' : ''}`}
                        onClick={() => changeForm('individual')}
                        //onClick={() => setFormType('individual')}
                    >
                        Individual
                    </div>
                    <div
                        className={`selector-button ${formType === 'grupal' ? 'active' : ''}`}
                        onClick={() => changeForm('grupal')}
                        //onClick={() => setFormType('grupal')}
                        >
                        Grupal
                    </div>
                </div>
            </div>
           {formType === 'grupal' && <span className='spanparticip'>Hasta 5 participantes por equipo</span>}
            <form className="subscription-form" onSubmit={onSubmit}>
                {formType === 'individual' ? (
                    <div className="form-individual">
                        <input
                            type="text"
                            name="nombre"
                            placeholder="Nombre"
                            value={formData.individual.nombre}
                            onChange={(e) => handleInputChange(e, 'individual')}
                            required
                        />
                        <input
                            type="text"
                            name="apellido"
                            placeholder="Apellido"
                            value={formData.individual.apellido}
                            onChange={(e) => handleInputChange(e, 'individual')}
                            required
                        />
                        <input
                            type="text"
                            name="pseudonimo"
                            placeholder="Pseudónimo del Proyecto"
                            value={formData.individual.pseudonimo}
                            onChange={(e) => handleInputChange(e, 'individual')}
                            required
                        />
                        <EmailForm
                            email={formData.individual.email}
                            setEmail={(email) => handleInputChange({ target: { name: 'email', value: email } }, 'individual')}
                        />
                        <input
                            type="number"
                            name="dni"
                            placeholder="DNI"
                            value={formData.individual.dni}
                            onChange={(e) => handleInputChange(e, 'individual')}
                            required
                        />
                        <div className="form-group">
                            <p className="form-label">Fecha de Nacimiento:</p>
                            <input
                                type="date"
                                name="fechaNacimiento"
                                value={formData.individual.fechaNacimiento}
                                onChange={(e) => handleInputChange(e, 'individual')}
                                required
                            />
                        </div>
                        
                           <LocationAutocomplete
                                    value={formData.individual.domicilio}
                                    onChange={(addressData) => handleLocationChange(addressData, 'individual')}
                                    placeholder="Domicilio"
                                    
                                />
                            <LocationAutocomplete
                                value={formData.individual.ciudad}
                                onChange={(value) => handleLocationChange(value, 'ciudad', 'individual')}
                                disabled={true}
                                placeholder="Ciudad"
                                
                            />
                            <LocationAutocomplete
                                value={formData.individual.provincia}
                                disabled={true}
                                onChange={(value) => handleLocationChange(value, 'provincia', 'individual')}
                                placeholder="Provincia"
                                
                            />
                            <PhoneForm
                              phoneNumber={formData.individual.telefono} 
                              setPhoneNumber={(number) => handleInputChange({ target: { name: 'telefono', value: number } }, 'individual')} 
                              
                            />
                            <FileUpload
                                label="Documentación del Proyecto"
                                fileType="proyecto"
                                onFileUpload={(e) => handleInputChange(e, 'individual')}
                                formType= {formType}
                            />
                            <FileUpload
                                label="Documentación del Participante"
                                fileType="participante"
                                onFileUpload={(e) => handleInputChange(e, 'individual')}
                                formType= {formType}
                            />
                            <FileUpload
                                label="Firma del Participante"
                                fileType="participanteFirma"
                                onFileUpload={(e) => handleInputChange(e, 'individual')}
                                formType= {formType}
                            />
                    </div>
                ) : (
                    <div className="form-grupal">
                        <input
                            type="text"
                            name="nombre"
                            placeholder="Nombre"
                            value={formData.grupal.nombre}
                            onChange={(e) => handleInputChange(e, 'grupal')}
                            required
                        />
                        <input
                            type="text"
                            name="apellido"
                            placeholder="Apellido"
                            value={formData.grupal.apellido}
                            onChange={(e) => handleInputChange(e, 'grupal')}
                            required
                        />
                        <EmailForm
                            email={formData.grupal.email}
                            setEmail={(email) => handleInputChange({ target: { name: 'email', value: email } }, 'grupal')}
                            required
                        />
                           <input
                            type="text"
                            name="pseudonimo"
                            placeholder="Pseudonimo del Proyecto"
                            value={formData.grupal.pseudonimo}
                            onChange={(e) => handleInputChange(e, 'grupal')}
                            required
                        />
                        
                        <input
                            type="number"
                            name="dni"
                            placeholder="DNI"
                            value={formData.grupal.dni}
                            onChange={(e) => handleInputChange(e, 'grupal')}
                            required
                        />
                          <div className="form-group">
                                        <p className="form-label">Fecha de Nacimiento:</p>
                                        <input
                                            type="date"
                                            name="fechaNacimiento"
                                            placeholder="Fecha de Nacimiento"
                                            value={formData.grupal.fechaNacimiento}
                                            onChange={(e) => handleInputChange(e, 'grupal')}
                                            required
                                        />
                            </div>
                            <LocationAutocomplete
                                    value={formData.grupal.domicilio}
                                    onChange={(addressData) => handleLocationChange(addressData,'grupal')}
                                    placeholder="Domicilio"
                                    required
                                />
                            <LocationAutocomplete
                                value={formData.grupal.ciudad}
                                onChange={(value) => handleLocationChange(value, 'ciudad', 'grupal')}
                                disabled={true}
                                placeholder="Ciudad"
                                required
                            />
                            <LocationAutocomplete
                                value={formData.grupal.provincia}
                                disabled={true}
                                onChange={(value) => handleLocationChange(value, 'provincia', 'grupal')}
                                placeholder="Provincia"
                                required
                            />
                            <PhoneForm
                                phoneNumber={formData.grupal.telefono} 
                                setPhoneNumber={(number) => handleInputChange({ target: { name: 'telefono', value: number } }, 'grupal')} 
                                required
                            />
                            <FileUpload
                                label="Documentación del Proyecto"
                                fileType="proyecto"
                                onFileUpload={(e) => handleInputChange(e, 'grupal')}
                                required
                                formType= {formType}
                            />
                            <FileUpload
                                label="Documentación del Participante"
                                fileType="participante"
                                onFileUpload={(e) => handleInputChange(e, 'grupal')}
                                required
                                formType= {formType}
                            />
                            <FileUpload
                                label="Firma del Participante"
                                fileType="participanteFirma"
                                onFileUpload={(e) => handleInputChange(e, 'grupal')}
                                required
                                formType= {formType}
                            />
                            <br />
                    {formData.grupal.participantes.map((participante, index) => (
                            <div key={index} className="participant-form">
                                <input
                                    type="text"
                                    name={`nombre_${index}`}
                                    placeholder="Nombre del Participante"
                                    value={participante.nombre}
                                    onChange={(e) => handleInputChange(e, 'grupal', index)}
                                    required
                                />
                                <input
                                    type="text"
                                    name={`apellido_${index}`}
                                    placeholder="Apellido del Participante"
                                    value={participante.apellido}
                                    onChange={(e) => handleInputChange(e, 'grupal', index)}
                                    required
                                />
                                <EmailForm
                                        email={participante.email}
                                        setEmail={(email) => handleInputChange({ target: { name: `email_${index}`, value: email } }, 'grupal', index)}
                                        required
                                    />
                                <input
                                    type="number"
                                    name={`dni_${index}`}
                                    placeholder="DNI del Participante"
                                    value={participante.dni}
                                    onChange={(e) => handleInputChange(e, 'grupal', index)}
                                    required
                                />
                                <div className="form-group">
                                    <p className="form-label">Fecha de Nacimiento:</p>
                                    <input
                                        type="date"
                                        name={`fechaNacimiento_${index}`}
                                        placeholder="Fecha de Nacimiento del Participante"
                                        value={participante.fechaNacimiento}
                                        onChange={(e) => handleInputChange(e, 'grupal', index)}
                                        required
                                    />
                                </div>
                                <LocationAutocomplete
                                    value={participante.domicilio}
                                    onChange={(addressData) => handleLocationChange(addressData, 'grupal',index)}
                                    placeholder="Domicilio"
                                    required
                                />
                                <LocationAutocomplete
                                    value={participante.ciudad}
                                    onChange={(value) => handleLocationChange(value, 'ciudad', 'grupal',index)}
                                    disabled={true}
                                    placeholder="Ciudad"
                                    required
                                />
                                <LocationAutocomplete
                                    value={participante.provincia}
                                    disabled={true}
                                    onChange={(value) => handleLocationChange(value, 'provincia', 'grupal',index)}
                                    placeholder="Provincia"
                                    required
                                />
                                <PhoneForm
                                    phoneNumber={participante.telefono} 
                                    setPhoneNumber={(number) => handleInputChange({ target: { name: `telefono_${index}`, value: number } }, 'grupal', index)} 
                                    required
                                />
                                <FileUpload
                                label="Documentación del Participante"
                                fileType="participante"
                                onFileUpload={(e) => handleInputChange(e, 'grupal', index)}
                                required
                                formType= {formType}
                               />
                                <button
                                    className='buttoneliminar'
                                    onClick={() => handleRemoveParticipant(index)}
                                >
                                    Eliminar
                                </button>
                            </div>
                        ))}
                        <button type="button" className='buttonadd' onClick={handleAddParticipant}>Agregar Participante</button>     
                    </div>
                    )
                }
                <ToastContainer />
                <button className='buttonSend' type="submit">Enviar</button>
            </form>
            {isModalOpen && (
                <ModalCaptcha 
                        onClose={() => setIsModalOpen(false)}
                        onSend={() => handleModalConfirmation()}/>
            )}
            {load && (<Loader/>)}
        </div>
    );
};