import React from 'react'

export const About = () => {
  return (
    <div>
      <div className="premios-section">
        <div>
            <h4>DISTINCION HORIZONTAL</h4>
        </div>
          <div>
              <p>
              Se seleccionará al menos 3 distinciones de manera horizontal, no se establecerá una escala de premios entre los/as ganadores/as.
              Cada ganador/a (distinguido/a) recibirá los siguientes premios:
              </p>
                <ul>
                  <li> Un monto de USD 1000 (dólar estadounidense mil).</li>
                  <li>Entrega de Diploma Proyecto Distinguido.</li>
                  <li>Posibilidad de firmar un contrato laboral con Goldmund S.A</li>
                  <li>Un eTermo Peabody</li>
                </ul>
          </div>
      </div>

      <div className="premios-section">
        <div>
            <h4>MENCION ESPECIAL</h4>
        </div>
        <div>
            <p>El jurado seleccionará al menos 5 menciones especiales quienes recibirán como premio un eTermo : </p>

            <p>
             *El premio se abonara en moneda de circulación legal, es decir en pesos argentinos, de acuerdo al
              tipo de cambio vendedor que establezca el Banco Nación Argentina al día anterior del efectivo
              pago.
              Los/as participantes renuncian expresamente a las previsiones del art. 765 del CCCN, acordando
              que pese a que el Precio Total se expresa en dólares estadounidenses, el mismo se cancela en pesos
              argentinos al tipo de cambio indicado
            </p>
        </div>
      </div>
    </div>
  );
};
