import React from 'react'

export const Fechas = () => {
  return (
    <div>
      <div className="premios-section">
        <p>
            Podran presentarse los proyectos desde 
            <br/>
            <span style={{color:'#007bff', fontSize:'20px'}}> el 14 DE OCTUBRE DE 2024 AL 10 DE MARZO DE 2025 </span>
        </p>
      </div>
     
    </div>
  );
};
