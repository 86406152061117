// ModalCaptcha.jsx
import React, { useState, useRef } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import './ModalCaptcha.css';
import Guia from '../../../Assets/Read/Guia.pdf'

export const ModalCaptcha = ({ onClose,  onSend }) => {
    const [acceptedTerms, setAcceptedTerms] = useState(false);
    const [captchaToken, setCaptchaToken] = useState('');
    const captcha = useRef(null);
    const [usuarioValido, setUsuarioValido] = useState(false);

    const handleCheckboxChange = (event) => {
        const { name, checked } = event.target;
        
        if (name === 'acceptedTerms') {
            setAcceptedTerms(checked);
        }
    };

    const handleRecaptchaChange = (value) => {
        if (value) {
            setCaptchaToken(value);
            setUsuarioValido(true);
        } else {
            setCaptchaToken('');
            setUsuarioValido(false);
        }
    };

    const handleModalConfirmation = () => {
        if (acceptedTerms && usuarioValido) {
            if (typeof  onSend === 'function') {
                onSend(); // Llama a la función de confirmación pasada como prop
            } else {
                console.error("onConfirm no es una función");
            }
            onClose();   // Cierra el modal
        } else {
            alert("Por favor, acepta los términos y completa el reCAPTCHA.");
        }
    };

    return (
        <div className="modal-overlay">
            <div className="modal-content">
                <button className="modal-close" onClick={onClose}>⨉</button>
                <h2>Confirmación</h2>
                <p>Para continuar, debes aceptar los<a href={Guia}target="_blank"rel="noopener noreferrer"> términos y condiciones </a>y completar el reCAPTCHA.</p>
                <div>
                    <input
                        type="checkbox"
                        name="acceptedTerms"
                        checked={acceptedTerms}
                        onChange={handleCheckboxChange}
                    />
                    <label>Acepto los términos y condiciones</label>
                </div>
                <ReCAPTCHA
                    ref={captcha}
                    sitekey="6LfjnkAqAAAAAOp9KYa5xism1gZC5GuJOTUIr2t3"  // Reemplaza con tu clave de sitio de Google reCAPTCHA
                    onChange={handleRecaptchaChange}
                />
                <button className='button-send'
                    onClick={handleModalConfirmation}
                    disabled={!acceptedTerms || !usuarioValido} // Desactiva el botón si no se cumplen las condiciones
                >
                    Confirmar
                </button>
            </div>
        </div>
    );
};
