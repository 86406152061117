import { firestore, doc, setDoc, addDoc, collection, storage } from '../FirebaseConfig'; // Asegúrate de importar los métodos correctos
import { sendEventBlue,  sendBulkEventBlue } from './EmblueHooks';
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";


//--------------------------- Función para formatear fecha a 'DD-MM-YYYY'----------------------------------------
const formatFechaNacimiento = (fecha) => {
  const dateObj = new Date(fecha);
  const year = dateObj.getFullYear();
  const month = String(dateObj.getMonth() + 1).padStart(2, '0'); // Mes en formato 2 dígitos
  const day = String(dateObj.getDate()).padStart(2, '0'); // Día en formato 2 dígitos
  //console.log('test')
  return `${day}-${month}-${year}`;
};
//---------------------------------------------------------------------------------------------------------------




//---------------------------- Función para subir archivos a Firebase Storage -----------------------------------
const uploadFileToStorage = async (file, folder, newFileName,pseudonimo) => {
  const storage = getStorage();
  const storageRef = ref(storage, `proyectos/${pseudonimo}/${folder}/${newFileName}`);
  
  try {
    // Sube el archivo a Firebase Storage
    const snapshot = await uploadBytes(storageRef, file);
    // Obtiene la URL del archivo subido
    const downloadURL = await getDownloadURL(snapshot.ref);
    console.log('Éxito en la carga del archivo');
    return downloadURL;
  } catch (error) {
    console.error("Error subiendo archivo a Firebase Storage:", error);
    throw new Error("No se pudo subir el archivo");
  }
};

const newDataValue = async (data) => {
  try {
    // Formatear la fecha de nacimiento
    const formattedFechaNacimiento = formatFechaNacimiento(data.fechaNacimiento);

    // Construir los nuevos nombres de archivo
    const proyectoFileName = `${data.pseudonimo}_${data.nombre}_${data.apellido}`;
    const participanteFileName = `${data.nombre}_${data.apellido}`;
    const participanteFirma = `${data.nombre}_${data.apellido}_Firma`;

    // Subir los archivos a Firebase Storage con los nuevos nombres
    const proyectoURL = await uploadFileToStorage(data.proyecto, 'proyectos', proyectoFileName, data.pseudonimo);
    const participanteURL = await uploadFileToStorage(data.participante, 'participantes', participanteFileName, data.pseudonimo);
    const firmaParicUrl = await uploadFileToStorage(data.participanteFirma, 'participantes', participanteFirma, data.pseudonimo) ;
    // Devolver el nuevo objeto con la fecha formateada y URLs de los archivos
    return {
      ...data,
      fechaNacimiento: formattedFechaNacimiento,
      archivoProyecto: proyectoURL,
      archivoParticipante: participanteURL,
      archivoFirma:firmaParicUrl,
      // No incluir objetos File en el resultado
    };
  } catch (error) {
    console.error("Error en newDataValue:", error);
    throw new Error("No se pudo procesar el formulario");
  }
};
//---------------------------------------------------------------------------------------------------------------

//--------------- Nueva función para subir archivos a Firebase Storage dentro de una carpeta del proyecto--------
const uploadFileToStorageInProjectFolder = async (file, projectName, folder, newFileName) => {
  const storage = getStorage();
  // Construye la ruta que incluye el nombre del proyecto como carpeta principal
  const storageRef = ref(storage, `proyectos/${projectName}/${folder}/${newFileName}`);
  
  try {
    // Sube el archivo a Firebase Storage
    const snapshot = await uploadBytes(storageRef, file);
    // Obtiene la URL del archivo subido
    const downloadURL = await getDownloadURL(snapshot.ref);
    console.log('Éxito en la carga del archivo en la carpeta del proyecto');
    return downloadURL;
  } catch (error) {
    console.error("Error subiendo archivo a la carpeta del proyecto en Firebase Storage:", error);
    throw new Error("No se pudo subir el archivo a la carpeta del proyecto");
  }
};
//-------------------grupal------------------------------
const newDataGroupValue = async (data) => {
  try {
    // Formatear la fecha de nacimiento del objeto principal
    const formattedFechaNacimiento = formatFechaNacimiento(data.fechaNacimiento);

    // Construir los nuevos nombres de archivo
    const proyectoFileName = `${data.pseudonimo}_${data.nombre}_${data.apellido}`;
    const participanteFileName = `${data.nombre}_${data.apellido}`;
    const participanteFirma = `${data.nombre}_${data.apellido}_Firma`;
    
    
    // Subir los archivos a Firebase Storage dentro de la carpeta del proyecto
    const proyectoURL = await uploadFileToStorageInProjectFolder(data.proyecto, data.pseudonimo, 'proyectos', proyectoFileName);
    const participanteURL = await uploadFileToStorageInProjectFolder(data.participante, data.pseudonimo, 'participantes', participanteFileName);
    const firmaParicUrl = await uploadFileToStorage(data.participanteFirma, 'participantes', participanteFirma, proyectoFileName) ;

    // Reemplazar la fecha y agregar las URLs de los archivos
    const groupData = {
      ...data,
      fechaNacimiento: formattedFechaNacimiento,
      archivoProyecto: proyectoURL,
      archivoParticipante: participanteURL,
      archivoFirma:firmaParicUrl,
      proyecto: '-' ,
      participante: '-' ,
      participanteFirma:'-' ,
    };

    // Procesar cada participante
    const participantesProcesados = await Promise.all(data.participantes.map(async (participante) => {
      const formattedFechaNacimientoParticipante = formatFechaNacimiento(participante.fechaNacimiento);
      
      const participanteFileName = `${participante.nombre}_${participante.apellido}`;
      
      const participanteURL = await uploadFileToStorageInProjectFolder(participante.participante, data.pseudonimo, 'participantes', participanteFileName);
      
      return {
        ...participante,
        fechaNacimiento: formattedFechaNacimientoParticipante,
        participante: participanteURL
      };
    }));

    // Devolver el objeto con los datos del grupo y los participantes procesados
    return {
      ...groupData,
      participantes: participantesProcesados
    };
  } catch (error) {
    console.error("Error en newDataGroupValue:", error);
    throw new Error("No se pudo procesar el formulario grupal");
  }
};
//---------------------------------------------------------------------------------------------------------------


//------------------------------------------- ENVIO A FIREBASE ---------------------------------------------------

export const handleSendForm = async (formData, formType) => {
  console.log('Datos del formulario:', formData);
  
  try {
    const collectionName = formType === 'individual' ? 'EmailEquipos' : 'EmailGroup';
    
    // Procesar el formulario dependiendo si es individual o grupal
    const documentData = formType === 'individual' ? await newDataValue(formData.individual) : await newDataGroupValue(formData.grupal);

    // Si es individual, enviar evento
    if (formType === 'individual') {
      const result = await sendEventBlue(documentData);
      console.log('Envio a sendEvent');
      if (!result.success) {
        alert('Error al enviar el evento: ' + result.message);
        return;
      }
    } 
    // Si es grupal, enviar evento grupal y luego los participantes individualmente
    else if (formType === 'grupal') {
      const { participantes, ...groupDataWithoutParticipants } = documentData;
      console.log('Envio a sendGroupEvent sin participantes');
      const groupResult = await sendBulkEventBlue(groupDataWithoutParticipants);
      if (!groupResult.success) {
        alert('Error al enviar el evento grupal: ' + groupResult.message);
        return;
      }
      
      for (const participante of participantes) {
        const individualResult = await sendBulkEventBlue(participante);
        if (!individualResult.success) {
          alert('Error al enviar el participante:' + individualResult.message);
          return;
        }
      }
    }

    // Excluir los objetos tipo `File` justo antes de enviar el documento a Firestore
    const { proyecto, participante, participanteFirma, ...documentWithoutFiles } = documentData;
    //documentData, 'antes de subir')
    // Crear el objeto final con el timestamp
    const documentToSave = {
      ...documentWithoutFiles,
      timestamp: new Date().toISOString(),
    };

    //console.log(documentToSave);

    const collectionRef = collection(firestore, collectionName);

    // Agregar el documento a Firestore
    const docRef = addDoc(collectionRef, documentToSave);
    console.log('Subida a Firebase correcta');
    // alert('Formulario enviado exitosamente');
  } catch (error) {
    console.error('Error al enviar el formulario:', error);
    // alert('Hubo un problema al enviar el formulario.');
  }
};

//---------------------------------------------------------------------------------------------------------------

export const handleGetInfo = async (formData) => {
  console.log('Datos del formulario:', formData);

  try {
    // Selecciona la colección correcta
    const collectionName = 'ObtencionData';
 
    const document = {
      ...formData,
      timestamp: new Date().toISOString()
    };

    // Crea una referencia a la colección
    const collectionRef = collection(firestore, collectionName);

    // Agrega el documento a la colección con un ID generado automáticamente
    const docRef = await addDoc(collectionRef, document);

    //console.log('Formulario enviado exitosamente');
    alert('enviado exitosamente');
  } catch (error) {
    console.error('Error al enviar el formulario:', error);
    alert('Hubo un problema al enviar el formulario.');
  }
};