import React from 'react'

export const Jurado = () => {
  return (
    <div>

    <div className="premios-section">
        <div>
            <h4>Pasos para la Inscripción</h4>
        </div>
        <div>
            <p>
                Las ideas participantes serán evaluadas por un Jurado multidisciplinario compuesto por cinco
                integrantes de reconocido prestigio profesional y académico.
                Composición del Jurado y Proceso de Evaluación
                El jurado estará compuesto por profesionales de diversas áreas relevantes, no limitándose solo a
                diseñadores industriales, sino incluyendo ingenieros, arquitectos, y otros expertos que aporten su
                conocimiento y experiencia. Además, se incorporarán instancias de corrección de proyecto con
                jurados o referentes especializados. Esto permitirá a los estudiantes recibir feedback constructivo y
                a la organización del premio tener una visión anticipada de las temáticas y el nivel de desarrollo de
                los proyectos. Desde principios de diciembre a febrero se podrá solicitar una única tutoría por grupo
                sobre trabajos avanzados, no ideas iniciales. Las mismas serán en modalidad virtual.
            </p>
            <ul> 
              El jurado estará conformado por:
                  <li> Victoria Rique</li>
                  <li>Federico Churba</li>
                  <li>Camila Offenhenden</li>
                  <li>Gabriela Lopez</li>
                  <li>Heidi Jalkh</li>
                  <li>Guillermo Duodero</li>
              </ul>
        </div>
    </div>

  
</div>
  )
}
