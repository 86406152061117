import React from 'react';
import './Material.css';
import { Footer } from '../Footer/Footer';
import ind from '../../Assets/ArkDesc/individualAutoria.docx'
import eqp from '../../Assets/ArkDesc/equipoAutoria.docx'

export const Material = () => {
  return (
    <div>
      <div className="material-container">
        <h1 className="material-title">Materiales Disponibles</h1>
        <div className='cardContent'>
          {/* Primera Card */}
          <div className="card">
            <div className="card-section-left">
              <div className="circle">
                <h1>Documentación para Participar</h1>
              </div>
            </div>
            <div className="card-section-right">
              <p>
                  Para poder participar Necesitamos La firma del Equipo o del participante a nuestros acuerdos y condiciones 
              </p>
                <div className='content-download-button'>
                  <div>
                    <a href={ind} download="individualAutoria.docx">
                      <button className="download-button">
                        Descargar 
                      </button>
                    </a> 
                    <span style={{color:'grey'}}> (Individual)</span>
                  </div>

                  <div>
                    <a href={eqp} download="equipoAutoria.docx">
                      <button className="download-button">
                        Descargar 
                      </button>
                    </a>
                    <span style={{color:'grey'}} > (Grupal)</span>
                  </div>
                </div>
            

              
            </div>
          </div>

          {/* Segunda Card */}
          <div className="card">
            <div className="card-section-left">
              <div className="circle2">
                <h1>Documentación Proyecto</h1>
              </div>
            </div>
            <div className="card-section-right">
              <p>
                Necesitamos que Crees un archivo PDF (de al menos 5 páginas), en las que podamos ver tu proyecto en 3D, documentación, comentarios, etc.
              </p>
            </div>
          </div>

          {/* Tercera Card */}
          <div className="card">
            <div className="card-section-left">
              <div className="circle">
                <h1>Documentación Participante</h1>
              </div>
            </div>
            <div className="card-section-right">
              <p>
                Necesitamos una foto en formato pdf, de tu Diploma escolar, o adjuntar título secundario.
              </p>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};
