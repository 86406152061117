import React from 'react'

export const Faqs = () => {
  return (
    <div>
        <div className="premios-section">
            <h4>PREGUNTAS FRECUENTES</h4>
              <p>
                  <strong>1. ¿Qué es el Premio Peabody? </strong>
                  <br /><br />
                  Es un concurso nacional de diseño, cuyos orígenes se remontan a 2017 cuando se realizó la primera edición de Premio. El Premio Peabody tiene como objetivo promover a estudiantes de las carreras de diseño al desarrollo de nuevas ideas y conceptos de productos. 
                  <br /><br />
                  <strong>2. ¿El Premio Peabody tiene una temática?</strong>
                  <br /><br />
                  Si, la temática de esta edición es "Más Allá del Presente: Diseñar el futuro Próximo" <br/><br />
                  Vivimos un momento de transformación global que afecta diversas disciplinas, desde la tecnología, con la irrupción de la inteligencia artificial como herramienta productiva, hasta la crisis medioambiental y las nuevas tendencias socioculturales del habitar. En este contexto, es esencial entender cómo los jóvenes estudiantes, futuros agentes de cambio, están abordando estos desafíos.
                  En esta edición del Premio Peabody, el enfoque invita a los participantes a desarrollar propuestas que respondan a estas cuestiones desde una perspectiva innovadora y reflexiva. La intención es que los proyectos no solo aborden el problema, sino que se materialicen en propuestas concretas y viables. Estos pueden manifestarse de diversas formas, desde productos de diseño y desarrollos tecnológicos hasta mejoras en procesos productivos con menor impacto ambiental.
                  <br /><br />
                  <strong>3. ¿Quiénes pueden participar?</strong>
                  <br /><br />
                  Buscamos la participación de estudiantes, como así también de graduados (máximo 1 año de finalizados los estudios con la totalidad de materias aprobadas) de diversas disciplinas para enriquecer el concurso con una gama amplia de conocimientos. Si bien el eje central sigue siendo el diseño argentino, alentamos la inclusión de perspectivas de otras áreas en torno al diseño, como ingeniería, arquitectura, y desarrollo tecnológico. Esto asegura una evaluación integral y multidimensional de las propuestas.
                  <br /><br />
                  Podrá participar toda persona mayor de 18 años, residente en la República Argentina, que acredite ser estudiante o graduado de la carrera de grado de diseño, ingeniería, arquitectura, y desarrollo tecnológico, tanto de universidades públicas como privadas, y que cumpla con todos los requisitos previstos en las presentes Bases. Podrán inscribirse de manera individual o grupal (en equipos de hasta 5 integrantes). En el caso de que se trate de un equipo, todos los integrantes deberán comprobar su condición de alumno regular, y en el supuesto de ser graduados, deberán presentar título o constancia de título en trámite.
                  <br /><br />
                  <strong> 4. ¿Se pueden presentar en grupo?</strong>
                  <br /><br />
                  Sí, en equipos de hasta 5 integrantes. En el caso de que se trate de un equipo, todos los/as integrantes deberán comprobar su condición de alumno/a regular.
                  <br /><br />
                  <strong>5. ¿Qué tipos de proyectos puedo presentar?</strong>
                  <br /><br />
                  Podrán presentarse conceptos e ideas de productos, sistemas, y mejoras de productos existentes donde esté implicado el diseño. Podrán también, presentarse nuevos negocios, aplicaciones e interfases siempre que haya un objeto asociado dentro del proyecto. No se considerarán proyectos que estén en el mercado o en producción.
                  <br /><br />
                  <strong>6. ¿Cuál es el contenido de la presentación?</strong>
                  <br /><br />
                  Se deberán presentar láminas tamaño A3, con un mínimo de 3 y un máximo de 5 láminas y un video corto de 30 segundos. Para mas información te recomendamos leer la guía de participación. (enlace a la guía)
                  <br /><br />
                  <strong>7. ¿Porque debe guardarse con un pseudónimo el archivo presentado?</strong>
                  <br /><br />
                  Solicitamos que el nombre del archivo presentado sea con un nombre fantasía (pseudónimo) con el fin de mantener el anonimato de los participantes durante el proceso de evaluación.
                  <br /><br />
                  <strong>8. ¿Hasta cuántos proyectos se pueden presentar?</strong>
                  <br /><br />
                    No hay límite en la cantidad de proyectos a presentar, pero no deben estar repetidos.
                  <br /><br />
                  <strong>9. ¿Cómo me inscribo?</strong>
                  <br /><br />
                  La inscripción es online completando el formulario en el sitio web www.premiopeabody.com.ar.
                  Para obtener más información te recomendamos leer la guía de participación (enlace a la guía) o escribirnos a inscripcion@premiopeabody.com.ar
                  <br /><br />
                  <strong>10. ¿Qué criterios de evaluación tendrá el jurado?</strong>
                  <br /><br />
                  El jurado evaluará los siguientes aspectos:
                  <br /> <br />
                        <ul>
                            <li>
                              Concepto: Como el concepto aborda la temática propuesta en el concurso y explora sobre los distintos modos de habitar.
                            </li>
                            <li>
                              Diseño: Cómo el proyecto combina forma, función y tecnología. Innovación: Grado de originalidad y novedad que propone.
                            </li>
                            <li>
                              Factibilidad: Potencial de viabilidad para su desarrollo a futuro. Responsabilidad: Cómo afecta el medio ambiente y cuál es su impacto social y cultural.
                            </li>
                            <li>
                            Como criterios adicionales, se evaluará la claridad del contenido y calidad de presentación.
                            </li>
                        </ul>
                  <br /><br />
                  <strong>11. ¿Cuál es el premio?</strong> 
                  <br /><br />
                  Se distinguirán tres proyectos de manera horizontal y habrá cinco menciones.

                  Cada ganador/a (distinguido/a) recibirá los siguientes premios:

                  un monto de $250.000 (pesos doscientos cincuenta mil)
                  un trofeo insignia del Premio Peabody
                  la Posibilidad de firmar un contrato laboral con Goldmund S.A
                  la participación en la muestra del Espacio Cultural Peabody, y futuras muestras o publicaciones que se realicen
                  un producto eTermo marca “Peabody”
                  Jurado seleccionará al menos 5 menciones
                  <br /><br />
                  <strong>12. ¿De dónde puede descargar las bases y condiciones?</strong>
                  <br /><br />
                  Las bases y condiciones se pueden descargar del sitio www.premiopeabody.com.ar, en la sección “materiales”.
            </p>
        </div>
    </div>
  );
};
