import React from 'react'

export const Participacion = () => {
  return (
    <div>
       <div className="premios-section">
        <div>
            <h4>Pasos para la Inscripción</h4>
        </div>
        <div>
            <p> 
            <strong>1.</strong> Completar todos los datos personales requeridos. <br /><br />
            <strong>2.</strong> Subir en el sitio web la constancia de Alumno regular, o Certificado de Materias aprobadas,
            emitido por la institución educativa correspondiente.<br /><br />
            <strong>3.</strong> Identificar si participa en “Grupo” o “Individual”.<br /><br />
            <strong>4.</strong> Si participa en “Grupo” agregar todos los datos requeridos de cada participante, junto con la
            constancia de Alumno regular, o Certificado de Materias aprobadas de cada uno.<br /><br />
            <strong>5.</strong> Cargar en el sitio web la Idea con la que se concursará, en formato PDF, de una extensión
            máxima de 5 páginas y un peso no mayor a 10 MB.<br /><br />
            <strong>6.</strong> Leer detenidamente las Bases y Condiciones. En caso de aceptarlas presione el ícono de Aceptar
            Bases y Condiciones.<br /><br />
            <strong>7.</strong> Una vez finalizado el proceso de Inscripción se le suministrará un código de participación que lo
            identificará como participante en todas las etapas previstas en las Bases y Condiciones.
            <br /><br />
            <strong>8.</strong> Dentro de las 48 horas recibirá en su casilla de mail un correo de
            inscripcion@premiopeabody.com.ar con el código del proyecto como asunto, donde se solicitará
            responderlo adjuntando la Carta de Autoría, la cual deberá descargarla de la sección “Materiales” de
            la página web www.premiopeabody.com.ar. La misma deberá estar firmada de puño y letra por el/la
            Participante (en caso de que concurse individualmente) o por el/la representante (en caso de que
            participe de manera grupal).<br /><br />
              </p>
        </div>
        </div>

        <div className="premios-section">
            <div>
                <h4>PRESENTACIÓN</h4>
            </div>
            <div>
                <p>
                Los/as Participantes deberán subir al sitio web www.premiopeabody.com.ar
                - Presentación en tamaño A3, con un mínimo de 2 y un máximo de 5 láminas.
                - Deberá contener una memoria Descriptiva de la Idea/Propuesta explicando su base conceptual,
                con precisiones sobre situación de uso y tecnología implicada en su funcionamiento.
                - La presentación debe contener imágenes, renders, infografías, dimensiones, y todo aquel material
                gráfico que se considere pertinente para explicar la idea.
                - Todo el material debe estar guardado en un archivo PDF que esté identificado con un pseudónimo
                del proyecto
                  </p>
            </div>
        </div>

      <div className="premios-section">
          <div>
              <h4>PARTICIPANTES</h4>
          </div>
          <div>
              <p>
                Buscamos la participación de estudiantes de diversas disciplinas para enriquecer el concurso con
                una gama amplia de conocimientos. Si bien el eje central sigue siendo el diseño argentino,
                alentamos la inclusión de perspectivas de otras áreas en torno al diseño, como ingeniería,
                arquitectura, y desarrollo tecnológico. Esto asegura una evaluación integral y multidimensional de
                las propuestas.
                Podrá participar toda persona mayor de 18 años, residente en la República Argentina, que acredite
                ser estudiante de la carrera de grado de diseño, ingeniería, arquitectura, y desarrollo tecnológico,
                tanto de universidades públicas como privadas, y que cumpla con todos los requisitos previstos en
                las presentes Bases. Podrán inscribirse de manera individual o grupal (en equipos de hasta 5
                integrantes). En el caso de que se trate de un equipo, todos los integrantes deberán comprobar su
                condición de alumno regular.
                </p>
          </div>
      </div>

      
    
  </div>
  
  )
}
